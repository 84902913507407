html {
  scroll-behavior: smooth;
}

.main-content {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  /* animation: fade-in 0.5s; */
}

div.fade-in{
  opacity: 100;
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

.main-content.fade-enter {
  opacity: 0;
}

.main-content.fade-enter-active {
  opacity: 1;
}

body {
  margin: 0;
  padding:0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(48, 90, 115);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p, div, h1, h2, h3, h4, h5, h6 {
  font-family:  'Roboto', 'Segoe UI';
  color:#243241;
}

h1 {
  font-size: 24px;
  font-weight: 600;
}
h2 {
  font-size: 20px;
  font-weight: 500;
}
h3 {
  font-size: 18px;
  font-weight: 400;
}
p {
  font-size: 16px;
  font-weight: 300;
}

a {
  color: #1a2837;
  text-decoration: underline;
  &:visited {
    color: #555;
  }
  &:hover {
    color: #35669a;
    text-decoration: none;
  }

}

div.main-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    min-height: 1vh;
    padding-left: max(30px, 12vw);
    padding-right:max(30px, 12vw);
    padding-bottom:60px;
    padding-top:40px;
    margin-top:-1px;
    z-index:-2;
    
  }
  div.main-content div.width-restricted-area {
    justify-content: left;
    width: 100%;
    max-width: 1200px;
  }