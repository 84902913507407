button.change-langugage-button {
  font-size: 12px;
  background-color: rgba(130, 168, 190, 0.3);
  padding:10px;
  border-radius: 4px;
  border: 1px solid rgba(130, 168, 190, 0.5);
  box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  /* float:inline-end; */
  text-decoration: none;
  cursor: pointer;
  color: #333;

  &:hover {
    color: #000;
    text-decoration: none;
    box-shadow: 0 1px 2px rgba(0,0,0,0.3);
    border: 1px solid rgba(130, 168, 190, 0.9);
    background-color: rgba(130, 168, 190, 0.5);
  }
}