footer{
    background-color: #305a73;
    color: #fff;
    text-align: center;
    padding: 10px;
    z-index: 1;
    height: 100px;
    padding-bottom:30px;
}

.footer-waves {
    background-color: #fff;;
    position:relative;
    width: 100%;
    height:40px;
    margin-top:-1px;
    z-index: -1;
    margin-bottom:-7px; /*Fix for safari gap*/
    /* border: 1px solid red; */
  }

  footer p.copy {
    font-family: 'Rajdhani', sans-serif;
    font-size: 16px;
    text-shadow: -2px -2px 1px rgba(0,0,0,.5);
    color:#fff;
    text-align: center;
  }
  footer .sections {
    margin: 30px 0;
  }
  footer .sections:after {
    content: "";
    display: table;
    clear: both;
  }

  footer .section {
    float:left;
    width: 50%;
  }

  footer .section-1 {
    float:left;
    text-align: left;
    align-items: left;
  }
  footer .section-1 p {
    font-family: 'Rajdhani', sans-serif;
    font-size: 14px;
    color:#fff;
    display:block;
    margin-left:30px;
    }
    footer .section-1 div.lang-switcher {
        margin-left:30px;
    }
    footer .section-1 div.lang-switcher button {
        font-family: 'Rajdhani', sans-serif;
        font-size: 14px;
        text-decoration:none;
        color:#fff;
        border:1px solid rgba(255,255,255,.2);
        background-color: rgba(255,255,255,.0);
        width: 60px;
        display: inline-block;
        padding: 2px;
        border-radius: 3px;
        margin-right:3px;
        margin-bottom:3px;
        cursor: pointer;
        &:hover {
          background-color:rgba(255,255,255,.2);
        }
        &:disabled {
          background-color: rgba(255,255,255,.1);
        }

    }


  footer .section-3 {
    float:right;
    text-align: right;
    align-items: right;
  }
 
    footer .section-3  nav {
        padding:0 30px;
        display: block;
        float:right;
    }
    footer .section-3  nav ul {
        list-style:none;
        padding:0;
        margin:0;
        align-items: right;
    }
    footer .section-3  nav ul li {
        margin-bottom:3px;
        text-align: center;
    }

    footer .section-3  nav ul li a {
        font-family: 'Rajdhani', sans-serif;
        font-size: 16px;
        text-decoration:none;
        color:#fff;
        border:1px solid rgba(255,255,255,.2);
        width: 100px;
        display: block;
        padding: 2px;
        border-radius: 3px;
    }

    footer .section-3  nav ul li a:hover {
        background-color:rgba(255,255,255,.2);
    }

    footer .section-3  nav ul li.selected a {
        background-color:rgba(255,255,255,.1);
    }

    @media (max-width: 450px) {
      footer .rights {
        display: none;
      }
    }