img.cover {
  width: 100%;
  height:380px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.4);
}

div.article-meta {
  font-size: 12px;
  text-align: left;

  margin-top:-10px;

}
div.article div.content-image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
}
