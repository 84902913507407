div.music-preview-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 20px;
  margin-top: 30px;
}

div.music-preview {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 20px 0;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: #dbeaf6;
  transition: all 0.3s;
  border:1px solid #a0a8ae;
}

div.music-preview:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  transform: translateY(-5px);
}

div.music-preview iframe {
  border-radius: 6px;
}

div.music-preview h3 {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 10px;
}

div.music-preview p.meta {
  font-size: 12px;
  text-align: left;
  margin-top: -6px;
  margin-bottom: 6px;
}

@media (max-width: 738px) {
  div.music-preview-grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}