div.latest-articles {
    margin-top:30px;
}
div.latest-articles-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
    margin-top: 30px;
}
div.article-preview {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 0 20px 0;
    padding: 20px;
    padding-bottom: 4px;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    transition: all 0.3s;
}

div.article-preview p.meta {
    font-size: 12px;
    text-align: left;
    margin-top: -8px;
    margin-bottom: 0px;
}


div.article-preview:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
}

div.article-preview img {
    width: 100%;
    height:180px;
    object-fit: cover;
    border-radius: 6px;
    margin-bottom: 20px;
}

div.article-preview div.placeholder-image {
    width: 100%;
    height: 180px;
    border: 1px solid rgba(65,158,199,0.9);
    background: linear-gradient(-45deg, rgba(0, 0, 0, 0.2), rgba(65,158,199,0.8), rgba(65,158,199,0.6), rgba(0, 0, 0, 0.1));
    background-size: 400% 400%;
    border-radius: 6px;
    margin-bottom: 20px;
    animation: gradientBG 15s ease infinite;
    position: relative;
}

div.article-preview a.read-more-link{
    color: #fff;
    background-color: #2c4965;
    padding: 8px 16px;
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s;
    font-size: 14px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 8px;
    display: inline-block;
    &:hover {
        background-color: #3b6185;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        transform: translateY(-3px);
    }
}

@keyframes gradientBG {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
@keyframes moveCloud {
    0% { transform: translateX(-100%); opacity: 0; }
    50% { opacity: 0.8; }
    100% { transform: translateX(100%); opacity: 0; }
}

/* div.article-preview div.placeholder-image::before {
    content: '';
    position: absolute;
    width: 30%;
    height: 10%;
    background: rgba(85, 175, 223, 0.9);
    border-radius: 20px;
    top: 40%;
    animation: moveCloud 8s linear infinite;
    animation-delay: 1s;
    left: 30%;
} */


div.article-preview div.placeholder-image::after {
    content: 'Image not available';
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    font-weight: bold;
    text-shadow: 0 0 2px rgba(85, 175, 223, 0.5);
}
