header {
    position:relative;
    text-align:center;
    background: linear-gradient(60deg, rgba(16,17,25,1) 0%, rgba(65,158,199,1) 100%);
    color:white;
    height:179px;
  }

  header .inner-header {
    position:absolute;
    top:0;
    left:0;
    height:180px;
    z-index: 2;
    width:100%;
    margin: 0;
    padding: 0;
  }

  header .flex { /*Flexbox for containers*/
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  header .waves {
    position:relative;
    margin-top:100px;
    width: 100%;
    height:80px;
    margin-bottom:-7px; /*Fix for safari gap*/
  }

  header nav {
    display:flex;
    float:right;
    justify-content:space-between;
    align-items:center;
    height:100px;
    padding:0 30px;
    position:absolute;
    left:0;
    top:0;
  }

  header nav ul {
    display:flex;
    list-style:none;
    padding:0;
    margin:0;
  }

  header nav ul li {
    margin:0 8px;
  }

  header nav ul li a, header nav ul li button {
    font-family: 'Rajdhani', sans-serif;
    font-size: 20px;
    text-shadow: 2px 2px 2px rgba(0,0,0,.7);
    text-decoration:none;
    color:#fff;
    &:visited {
      color:#fff;
    }
    &:hover {
      color:rgba(255,255,255,.7);
    }
  }
  header nav ul li button{
    cursor: pointer;
    background-color: rgba(255,255,255,.0);
    border:0;
    padding:0;
    margin:0;
  }

  header nav ul li.selected a{
    color:#a4cce2;
    text-shadow: -2px -2px 1px rgba(0, 0, 0, 0.7);
    border-bottom: 1px solid  rgba(16,17,25,.5);
  }
 

  header div.h1 {
    font-family: 'Rajdhani', sans-serif;
    text-transform: lowercase;
    font-weight:500;
    letter-spacing: -7px;
    font-size:70px;
    vertical-align: bottom;
    text-shadow: 4px 4px 8px rgba(0,0,0,.5);
    position:absolute;
    top:0;
    right:30px;
    color:#fff;
  }
  header div.h1 div.h2{
    font-family: 'Rajdhani', sans-serif;
    text-transform: lowercase;
    font-weight:300;
    letter-spacing: 0px;
    font-size:20px;
    text-align: right;
    vertical-align:top;
    margin-top:-30px;
    margin-right: -3px;
    text-shadow: 2px 2px 4px rgba(0,0,0,.9);
    color:#fff;
  }

  /* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% { 
    transform: translate3d(85px,0,0);
  }
}
header .sidenav-button {
  display: none;
}

/* div.lang-switcher-main {
  position:absolute;
  top:20px;
  
}
div.lang-switcher-main button{
  margin: 0 2px;
  width: 40px;
} */



/*Shrinking for mobile*/
@media (max-width: 500px) {
  header {
    height:40vw;
    /* min-height: 100px; */
  }
  nav.sidemenu-links {
    display: block;
    height: 100%;
    margin-top:60px;
  }
  nav.sidemenu-links ul {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    height: 100%;
  }
  nav.sidemenu-links ul li {
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: left;
    margin-bottom:6px;
  }
  nav.sidemenu-links a {
    color: #111;
    text-shadow: none;
    font-weight: bold;
    &:visited {
      color: #f4f4f4;
    }
  }
  nav.sidemenu-links li.selected a {
    color: #f4f4f4;
    text-shadow: none;
  }
  header .inner-header {
    height:30vw;
  }
  header .waves {
    height:20vw;
    min-height:20px;
    margin-top:21vw;
  }
  .content {
    height:90px;
  }
  h1 {
    font-size:24px;
  }

  header div.h1 {
    font-family: 'Rajdhani', sans-serif;
    text-transform: lowercase;
    font-weight:500;
    letter-spacing: -1.9vw;
    font-size:15.5vw;
    vertical-align: bottom;
    text-shadow: 4px 4px 8px rgba(0,0,0,.5);
    position:absolute;
    top:0;
    right:30px;
    color:#fff;
  }
  /* Hide corporation text */
  header div.h1 div.h2{
    display:none;
  }
  header nav ul li a {
    font-size: 4.5vw;
  }
  header nav{
    display: none;
  }
  header .sidenav-button {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    z-index: 3;
  }
  header .menuicon {
    color:#e3e3e3;
    margin-top: 5px;
    margin-left: 5px;
  }
  div.sidemenu div.lang-switcher {
    position:absolute;
    bottom:60px;
    width:100%;
    left:0;
  }
  div.sidemenu ul li {
    margin-bottom: 12px;
  }
  
  div.sidemenu ul li  a {
    font-size: 24px;
    color: #f4f4f4;
  }
  /* div.lang-switcher-main {
    display:none;
  } */
  div.sidemenu div.lang-switcher button {
      font-family: 'Rajdhani', sans-serif;
      font-size: 20px;
      text-decoration:none;
      color:#fff;
      border:1px solid rgba(255,255,255,.2);
      background-color: rgba(255,255,255,.0);
      width: 80px;
      display: inline-block;
      padding: 8px;
      border-radius: 3px;
      margin-right:6px;
      margin-bottom:3px;
      cursor: pointer;
      &:hover {
        background-color:rgba(255,255,255,.2);
      }
      &:disabled {
        background-color: rgba(255,255,255,.1);
      }

  }
}