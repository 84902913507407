.loading-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding: 20px;
  }
  
  .loading-spinner {
    display: flex;
    gap: 4px;
  }
  
  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #56779a;
    animation: wave 1.2s ease-in-out infinite;
  }
  
  .dot:nth-child(1) { animation-delay: 0s; width:16px; height: 12px; }
  .dot:nth-child(2) { animation-delay: 0.1s; }
  .dot:nth-child(3) { animation-delay: 0.2s; }
  .dot:nth-child(4) { animation-delay: 0.3s; }
  .dot:nth-child(5) { animation-delay: 0.4s; }
  .dot:nth-child(6) { animation-delay: 0.5s; }
  
  @keyframes wave {
    0%, 100% {
      transform: translateY(0);
      background: #446385;
    }
    50% {
      transform: translateY(-20px);
      background: #6594c7;
    }
  }